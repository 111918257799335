@import "../../assets/styles/variables";
@import "../../assets/styles/common";

.processes {
  margin: auto;
  background-color: $sGrey-12;
}

.processes-wrapper {
  min-height: calc(100vh - 15em);
  padding-bottom: 3rem !important;
}

.processes .table-header {
  display: flex;
  align-items: center;
  color: $sGrey-0;
  background: $sGrey-12;
  font-size: medium;
  flex-wrap: wrap;
}

%product-image {
  margin: 0.7em 0.7em 0.2em 1.3em;
  width: 5.9em;
  height: 5.9em;
  background-color: $sGrey-30;
  border-radius: $sborder-radius-s;
}

.processes .image-filler {
  object-fit: fill;
  @extend %product-image;
  padding: 1.2em 1em;
}

.processes .image-actual {
  object-fit: cover;
  @extend %product-image;
}

.container {
  padding: 2em;
}

.cursor {
  cursor: pointer;
}

.cursor-no {
  cursor: default;
}

.padding-top {
  padding: 1px 0 0 0;
}

.padding-left {
  padding: 0 0 0 2.8em;
}

.perf-top-margin {
  margin-top: 1.3em;
}

.perf-text-align {
  display: block;
  align-self: center;
}

.perf-asset-align {
  display: flex;
  align-items: baseline;
}

.processes .table-header-style {
  color: $sGrey-1 !important;
  background: $sGrey-12 !important;
  @extend .medium-text;
  font-weight: 500 !important;
  border: none !important;
  padding-bottom: 1.3rem !important;
  box-shadow: none !important;
}

.processes .perf-common {
  color: $sGrey-0;
}

%dot {
  height: 1.25em;
  width: 1.25em;
  border-radius: 50%;
}

.red-dot {
  @extend %dot;
  background-color: $sRed-0;
}

.green-dot {
  @extend %dot;
  background-color: $sGreen-0;
}

.no-padding-left-right {
  padding-left: 0;
  padding-right: 0;
}

.no-padding {
  padding: 0;
}

.no-bottom-padding {
  padding-bottom: 0;
}

.processes-logo {
  display: flex;
  align-items: center;
  margin: 1.4em 0 0.68em 0;
  @extend .xxx-large-text;

  span:last-child {
    margin-left: 0.7em;
  }
}

.filter-dropdown {
  display: flex;
}
.filter-dropdown-end {
  display: flex;
  justify-content: flex-end;
}

.filter-text {
  @extend .ex-large-text;
}

.tableIcons {
  display: flex;
  align-content: center;
  justify-content: flex-end;
}

.iconContainer {
  display: flex;
  align-items: center;
}

.info-icon {
  height: 1.7em;
  width: 1.7em;
}

.processes .i-icon-size {
  width: 1.38em;
  height: 1.38em;
}

.processes .info-straight-line {
  @extend .info-straight-line;
  width: 97%;
}

.sortIcon {
  padding: 0.2em 1em 0.2em 1em;
  border: thin solid $sWhite-2;
  border-style: none none none solid;
}

.sortIcon-mid {
  padding: 0.2em 1em 0.2em 0.2em;
}

.sortIcon-end {
  padding: 0.2em 2em 0.2em 1em;
  border: thin solid rgba(255, 255, 255, 0.2);
  border-style: none none none solid;
}

.dim-40 {
  opacity: 0.4;
  cursor: pointer;
}

.dim-40:hover {
  opacity: 0.8;
}

.dim-no {
  opacity: 1;
  cursor: default;
}

.selected-column {
  background-color: $sGrey-8;
}

.expand-button {
  padding: 1em 1.4em;
  background-color: $sBlue-1 !important;
  border-color: $sBlue-1 !important;
  border-radius: $sborder-radius;
}

.p-datatable {
  font-family: $font-family-base;

  tr[role="row"]:hover td{
    background: $sGrey-19;
  }
}

.processes .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.6rem 0.6rem;
  font-size: 0.75rem;
}

.processes
  .p-datatable
  .p-datatable-tbody
  > tr:not(.p-datatable-emptymessage)
  > td:hover {
  background: $sGrey-19;
}

.p-datatable .p-datatable-header {
  border: none;
  padding: 0;
}

.p-badge.p-badge-info {
  background-color: $sBlue-0;
  border-radius: 18px;
  width: 2rem;
  height: 2rem;
  margin: 0.5em;
  color: $sWhite;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-style: inset;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:nth-child(8n) {
  border-right-width: 0px;
}

.processes .p-button:enabled:focus {
  box-shadow: none;
}


.processes .p-datatable .p-datatable-tbody > tr {
  background-color: $sWhite;
}

.processes
  .p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr:nth-child(even) {
  background-color: $sWhite;
}

.processes .p-datatable .p-datatable-thead > tr > th {
  padding: 1rem 1.5rem;
}

.processes .p-datatable .p-datatable-tbody > tr > td {
  border-top: 1px solid $sGrey-12;
  border-bottom: 1px solid $sGrey-12;
  padding: 0;

  .padding-cursor {
    padding: 1.5rem;

    .col-12 {
      padding: 0;
    }
  }

  .cursor {
    padding: 1.5rem;

    .col-12 {
      padding: 0;
    }
  }
}

::placeholder {
  color: $sWhite;
}

.processes .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: $sGrey-1;

  &.pi-sort-alt {
    display: none;
  }
}

.processes .no-data-msg .p-datatable .p-datatable-tbody > tr {
  background-color: $sBlue-0;
}
.processes
  .no-data-msg
  .p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr:nth-child(even) {
  background-color: $sBlue-0;
}
.processes .no-data-msg .p-datatable .p-datatable-tbody > tr > td {
  border: none;
}

.processes .display {
  background: $sGrey-12;

  .footer {
    color: $sWhite;
    padding: 0;

    .logout-title {
      color: $sWhite !important;
    }

    .logout-icon {
      border-color: $sWhite !important;
    }
  }
}

.highlight-clickable-opacity:hover {
  opacity: 0.6;
}

.processes .p-datatable .p-datatable-thead > tr > th:not(:nth-child(8)):hover {
  span {
    opacity: 0.6;
  }
}

.display.wrap-center {
  max-width: $max-width-1480;
}

.bold-text {
  margin: 0;
  margin-bottom: 1rem;
  font-weight: 500;
  color: $sGrey-0;
  font-size: 1rem;
}

.processes-data-table .datatable-gridlines .p-datatable-tbody > tr > td .p-column-title {
  display: none;
  width: 100%;
  position: relative;
  margin-bottom: 0.4rem;
}

@media (max-width: $breakpoint-s) {
  .processes-data-table .datatable-gridlines .p-datatable-thead > tr > th,
  .processes-data-table .datatable-gridlines .p-datatable-tfoot > tr > td {
      display: none !important;
  }

  .processes-data-table .datatable-gridlines .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none;
  }

  .processes-data-table .datatable-gridlines .p-datatable-tbody > tr > td .p-column-title {
    display: inline-block;
    font-weight: 500;
  }

  .processes-data-table .datatable-gridlines .p-datatable-tbody > tr > td:last-child {
      border-bottom: 1px solid $sGrey-19;
  }
}

.center-dot {
  display: flex;
  justify-content: center;
}

.p-button.p-button-link.action-btn {
  color: $sGrey-1;
  font-size: 0.75rem;

  &:hover {
    background-color: $sGrey-20;
    color: $sGrey-1;
  }

  img {
    margin-right: 0.5rem;
  }
}

.flex-wrapper.col-5 span.mobile-vis, 
.flex-wrapper.col-7 span.mobile-vis, 
.flex-wrapper > div span.mobile-vis {
  margin-right: 0.2rem;
  display: none;
}

.flex-wrapper {
  display: flex;
  flex-direction: column;

  & > div:first-child {
    margin-bottom: 0.25rem;
  }

  &.col-5, &.col-7, > div {
    padding: 0;

    p {
      margin: 0;
      display: flex;
      justify-content: flex-start;
    }

    span {
      display: flex;
      align-items: center;
      img {
        min-width: 14px;
        min-height: 14px;
        margin-right: 7px;
      }
    }
  }

  &:first-child > div {
    p span:first-child {
      min-width: 130px;
    }
  }

  &:last-child > div {
    p span:first-child {
      min-width: 100px;
    }
  }
}

.last-run-span {
  img {
    margin-left: -1px;
  }
}

.upload-config-btn-wrap {
  margin-left: auto;
}

.upload-config-btn {
  .p-button {
    background-color: $sBlue-1;
    border-radius: .313rem;
    height: 3.25rem;
    padding: .125rem 1.25rem;
    line-height: .813rem;
    border: 0;

    &:not(button):not(a):not(.p-disabled):hover,
    &:not(button):not(a):not(.p-disabled):active {
      background: $sBlue-1;
      border: 0;
      opacity: 0.5;
    }
  }

  & > div {
    display: none;
  }

  .p-button-icon {
    display: none;
  }
}

.process-details {
  display: flex;
}

@media (max-width: $breakpoint-l) {
  .process-details {
    flex-wrap: wrap;
  }

  .flex-wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    & > div p span:first-child {
      min-width: 130px;
    }

    & > div {
      margin-bottom: 0.25rem;
    }
  }

  .flex-wrapper:last-child {
    & > div p span:first-child {
      min-width: 130px;
    }
  }
}

.flex-wrapper {
  @media (max-width: $breakpoint-m) {
    &:first-child > div {
      p span:first-child {
        min-width: auto;
      }
    }

    &:last-child > div {
      p span:first-child {
        min-width: auto;
      }
    }

    &.col-5 span.mobile-vis, 
    &.col-7 span.mobile-vis, 
    & > div span.mobile-vis {
      display: inline-block;
    }
  }
}

.processes .p-datatable .p-datatable-tbody > tr > td .padding-cursor {
  @media (max-width: $breakpoint-m) {
    padding: 0;
  }
}

.p-datatable .p-datatable-thead > tr > th[style*=" text-align: left"] {
  @media (max-width: $breakpoint-m) {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: $breakpoint-m) {
  .filter-dropdown {
    flex-wrap: wrap;
    &.col-6 {
      width: 100%;

      & > .col-6  {
        width: calc(50% - (24px / 2));
      }
    }
  }
  .upload-config-btn-wrap {
    margin-left: 0;
    margin-top: 1rem;
  }
}

@media(max-width: $breakpoint-s) {
  .filter-dropdown {
    .p-inputtext {
      padding: 0;
      font-size: 12px;
    }
    
    .p-dropdown .p-dropdown-label.p-placeholder {
      font-size: 12px;
    }
  } 

 .display.wrap-center {
    padding: 1rem;
  }

  .processes .p-datatable .p-datatable-tbody > tr > td {
    width: 100% !important;
    text-align: left !important;
  }
  .processes-logo {
    margin: 0.5rem 0 0 0;
    font-size: 140%;
  }

  .processes .p-datatable .p-datatable-tbody > tr > td .padding-cursor,
  .processes .p-datatable .p-datatable-tbody > tr > td .cursor {
    padding: 1rem;
  }
  .p-button.p-button-link.action-btn {
    background: $sGrey-20;
    padding: 0.5rem 0.6rem;

    &:hover {
      background: $sGrey-22;
    }
  }

  .p-col-12.center-dot {
    display: inline;
    width: auto;
    padding-left: 0;
  }

  .status-dot {
    display: flex;
    align-items: center;

    .col-12 {
      width: auto;
    }
  }

  .processes .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: $sGrey-8;
  }

  .processes .p-datatable .p-datatable-tbody > tr > td .padding-cursor.action-wrap {
    padding-top: 0;
    padding-bottom: 0;
  }

  .processes .p-datatable .p-datatable-tbody > tr > td .padding-cursor.type-wrap {
    padding-top: 0;
  }

  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding: 0;
    font-size: 12px;
  }

  .upload-config-btn .p-button {
    height: 2.2rem;
    font-size: 12px;
  }

  .p-dropdown .p-dropdown-trigger {
    width: auto;
  }

  .sDropdown {
    padding: 0.6rem 0.75rem !important;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.6rem 0.75rem !important;
    
  }
  .dropdown-item-2 {
    margin: 0 !important;
    font-size: 12px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item .dropdown-panel-item-horiz-line {
    display: none;
  }

  .p-dropdown .p-dropdown-clear-icon {
    display: none;
  }
}

.p-filter-column {
  display: none;
}

.processes .p-datatable .p-datatable-tbody > tr.p-datatable-emptymessage > td {
  padding: 1.5rem;
  font-size: 100%;
}